<template>
  <div class="swiper-box">
    <div class="swiper joinUsSwiper">
      <div
        class="swiper-wrapper"
        :style="
          realIndex === recruitList.length - 3 && recruitList.length > 5
            ? 'margin-left:' + '-' + marginLeftValue + 'vw'
            : ''
        "
        :class="
          realIndex === recruitList.length - 3 && recruitList.length <= 5 && recruitList.length > 4
            ? 'last-right1'
            : realIndex === recruitList.length - 3 && recruitList.length > 5
            ? 'last-right'
            : realIndex === recruitList.length - 3 && recruitList.length <= 4
            ? 'last-right2'
            : ''
        "
      >
        <div
          class="swiper-slide swiper-no-swiping"
          v-for="(params, index) in recruitList"
          :key="index"
          :style="index === 0 ? 'margin-left: 17vw' : ''"
        >
          <div class="item">
            <div class="item irohamaru">
              <div class="three">
                <div class="title">
                  {{ params.post }}
                </div>
                <div class="content">
                  {{ params.duty }}
                </div>
                <div class="information">め-るばっくす：{{ params.email }}</div>
                <div class="button">
                  <div class="btn flex row-center col-center" @click="handleDetails(params)">
                    詳細を確認
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <btn ref="btn" :recruitList="recruitList" @next="next" @pre="pre"></btn>
  </div>
</template>

<script>
import Swiper from 'swiper'
import 'swiper/css/swiper.min.css'
import btn from './btn.vue'
export default {
  components: {
    btn
  },
  props: {
    recruitList: {
      type: Array
    }
  },
  data() {
    return {
      realIndex: 0,
      marginLeftValue: 7,
      swiper: null,
      list: [{}, {}, {}, {}, {}, {}, {}, {}]
    }
  },
  mounted() {
    this.initSwiper()
    if (this.recruitList.length === 6) {
      this.marginLeftValue = 7
    } else {
      this.marginLeftValue = 5.5 * (this.recruitList.length - 6)
    }

    console.log(this.marginLeftValue)
  },
  methods: {
    handleDetails(e) {
      this.$emit('getDetails', e)
    },
    getIndex(e) {
      // console.log(e)
      // this.realIndex = e
      // this.next()
      // this.$refs.btn.getIndex(e)
    },
    initSwiper() {
      this.swiper = new Swiper('.joinUsSwiper', {
        slidesPerView: 3,
        noSwiping: true,
        width: 420 * 3,
        spaceBetween: 17
      })
    },
    next(e) {
      console.log('回调')
      console.log(e)
      console.log(this.recruitList.length)
      this.realIndex = e
      this.swiper.slideNext()
    },
    pre(e) {
      this.realIndex = e
      this.swiper.slidePrev()
    }
  }
}
</script>

<style scoped lang="scss">
.swiper-box {
  .last-right {
    //margin-left: -420px;
  }
  .last-right1 {
    margin-left: -4.2vw;
  }
  .last-right2 {
    margin-left: -3.5vw;
  }
  //width: 90%;
  //margin-left: 10%;
  .swiper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    //padding: 0 300px;
    .swiper-wrapper {
      .swiper-slide {
        width: 420px !important;

        .item {
          width: 420px;
        }
        .first {
          margin-left: 100px;
        }
        .last {
          margin-right: 100px;
        }
      }
      .custom-pagination {
        position: absolute;
        z-index: 10;
        bottom: 10%;
        width: 100%;
        gap: 14px;
      }
    }
  }
}

.first {
  margin-left: 330px;
}
.last {
  //margin-left: 100px;
}
.three {
  width: 420px;
  height: 480px;
  box-sizing: border-box;
  padding: 104px 34px 53px 34px;
  border-radius: 18px;
  background: #000;
  //margin-right: 20px;

  .title {
    color: $light-color;
    font-size: 28px;
    font-weight: 500;
    line-height: 35px; /* 125% */
    letter-spacing: 0.196px;
    margin-bottom: 17px;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    word-wrap: break-word;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  .content {
    color: $light-color;
    font-size: 17px;
    font-weight: 400;
    line-height: 25px; /* 147.059% */
    margin-bottom: 17px;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    word-wrap: break-word;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  .information {
    color: $light-color;
    font-size: 17px;
    font-weight: 500;
    line-height: 25px; /* 147.059% */
  }
  .button {
    margin-top: 67px;
    display: flex;
    align-items: center;
    justify-content: center;
    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 138px;
      height: 42px;
      border-radius: 980px;
      background: #bbb290;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
      color: rgba(255, 255, 255, 1);
      font-weight: bold;
      font-size: 17px;
      line-height: 25px;
      letter-spacing: 0.3px;
      cursor: pointer;
      position: absolute;
      z-index: 1000;
      bottom: 53px;
    }
  }
}
::v-deep .el-button--medium.is-round {
  width: 138px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 8px 26px;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  background: #bbb290;
}
.disabled {
  color: #4f4f4f;
  cursor: default;
  opacity: 0.36;
  background: rgba(66, 66, 69, 0.72);
}
.highlight {
  cursor: pointer;
  background: rgba(66, 66, 69, 0.72);
}
.hover {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.2) 100%),
  rgba(66, 66, 69, 0.72);
}
::v-deep .el-button--primary {
  border-color: #bbb290;
}
</style>
